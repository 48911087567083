<template>
  <v-card flat>
    <resource-header
      v-model="selectedTab"
      title="Products"
      :tabs="tabs"
      @zoomIn="maxWidth < 2000 ? maxWidth += 200 : null"
      @zoomOut="maxWidth > 400 ? maxWidth -= 200 : null"
    >
    </resource-header>
    <v-card-text>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              align="center"
            >
              <v-card
                flat
                :maxWidth="maxWidth"
              >
                <pdf
                  :src="tab.src"
                  @loaded="loading=false"
                  class="ma-5"
                  @num-pages="setPageNum"
                >
                </pdf>

                <div
                  v-for="i in pageNum"
                  :key="i"
                >
                  <pdf
                    :src="tab.src"
                    class="ma-5"
                    :page="i"
                    v-if="i != 1"
                  >
                  </pdf>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import resourceHeader from "../../../components/helpers/resource_header.vue";
import pdf from "vue-pdf";
import edi from "../../../assets/pdfs/edi.pdf";
import msv from "../../../assets/pdfs/msv.pdf";
import ppc from "../../../assets/pdfs/ppc.pdf";
import time from "../../../assets/pdfs/time.pdf";
import wms from "../../../assets/pdfs/wms.pdf";
export default {
  name: "Products",
  components: {
    resourceHeader,
    pdf,
  },
  props: {},
  data: () => ({
    pageNum: 1,
    maxWidth: 2000,
    loading: false,
    selectedTab: null,
    tabs: [
      {
        title: "COBI.wms",
        src: wms,
        btnColor: "#b31616",
      },
      {
        title: "COBI.time",
        src: time,
        btnColor: "#b51818",
      },
      {
        title: "COBI.edi",
        src: edi,
        btnColor: "#b72020",
      },
      {
        title: "COBI.msv",
        src: msv,
        btnColor: "#b92222",
      },
      {
        title: "COBI.ppc",
        src: ppc,
        btnColor: "#c12424",
      },
    ],
  }),
  watch: {
    selectedTab(newVal) {
      this.addParams({
        product: this.tabs[newVal].title,
      });
    },
  },
  methods: {
    setPageNum: function (value) {
      if (value) this.pageNum = value;
    },
  },
  mounted() {
    var product = this.$route.query["product"];
    if (product) {
      this.selectedTab = this.tabs.findIndex((x) => x.title == product);
    } else {
      this.selectedTab = 0;
    }
  },
};
</script>